.navbarbrand {
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  color: white;
}

.navbar-default {
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  color: white;
}

.home-text-on-image {
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  color: white;
}
.home-text-on-image-2 {
  font-size: 35px;
  font-weight: normal;
  font-style: italic;
  color: white;
}

.text-on-image-medium {
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  color: white;
}

.form-rc {
  border-radius: 15px;
}

.withBgImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/public/landingbackground.jpg);
  /* background-image: url(/public/oresund.webp); */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  width: 100%;
  height: 50vh;
}
